
import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';

const AssistanceContext = createContext();

export const AssistProvider = ({ children }) => {
  const [assistCategoryList, setassistCategoryList] = useState([]);
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    axios.get(apiUrl+'/v1/RoadAssistance/GetList?LanguageId=1')
      .then(response => {

        const loadedAssistances = response.data.Data

        setassistCategoryList(loadedAssistances);
       
      })
      .catch(error => {
        console.error('data could not be loaded', error);
      });
  }, []);

 
  

  return (
    <AssistanceContext.Provider value={assistCategoryList}>
      {children}
    </AssistanceContext.Provider>
  );
};

export const useAsistanceCategory = () => useContext(AssistanceContext);
