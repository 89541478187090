import React, { useEffect, useState } from "react";
import axios from "axios";
import DOMPurify from "dompurify";
import { Link } from "react-router-dom";
import { useLocalization } from '../../contexts/LocalizationContext';
import { createMarkup } from '../../helpers/utils';
import parse from 'html-react-parser';
import Loading from "../../component/loading/Loading";
import SEO from "../../component/content/Seo";
import AssistPackageComponent from "./AssistPackage";



const AssistCategoryComponent = ({ id, languageId }) => {
  // document.body.style.backgroundColor = '#c5d6ce';

  const localization = useLocalization();
  const BASE_URL = process.env.REACT_APP_API_URL;

  const [pageContent, setPageContent] = useState(null);

  useEffect(() => {
    axios
      .get(`${BASE_URL}/v1/RoadAssistance/Get`, {
        params: {
          DataId: id,
          LanguageId: languageId,
        },
      })
      .then((response) => {

        const safeHTML = DOMPurify.sanitize(response.data.FullText);
        response.data.FullText = safeHTML;

        setPageContent(response.data);
      })
      .catch((error) => {
        console.error("Sayfa içeriği yüklenemedi!", error);
      });
  }, [id, languageId]);

  if (!pageContent)
    return (
      <div>
            <Loading />
      </div>
    );

  return (



    <div>
            <SEO
    title={pageContent.Data.Name}
    description={pageContent.Data.MetaDesc}
    keywords={pageContent.Data.MetaKey}
    author="TollTake"
    image="https://www.tolltake.com/assets/img/tolltake-logo.svg"

  />
      <div className="container mx-auto">
      <div class="lg:flex gap-10 justify-between items-end mt-7">
    
<div>
  
<h2 class="text-green lg:text-hlg  text-lgheads font-semibold mb-2">{pageContent.Data.Name}</h2>

<p class="text-green text-hmdxp font-light lg:mb-0 mb-5">{pageContent.Data.ShortDescription	}</p>
<div class="twoSection mt-5">
        <img src={pageContent.Data.Picture}></img>
        <img src="https://cdn.tolltake.com/content/userfiles/editor/images/credit.png"></img>
        </div>    
</div>

<div>
  {/* <a href="/buy" class="px-8 hover:bg-orange bg-green text-white py-4 rounded-xl inline-block">Vinyet Satın Al</a> */}
  </div>
</div>
     
      </div>

      <div className="container mx-auto">
        <div className="lg:mx-0 page-text assist">
          {/* <div
            dangerouslySetInnerHTML={{ __html: pageContent.Data.FullText }}
          /> */}

          
            <AssistPackageComponent plans={pageContent.Data.Packages} />   
        
        </div>
      </div>
    </div>
  );
};

export default AssistCategoryComponent;
