import React, { useEffect, useState } from 'react';
import { useParams, Redirect } from 'react-router-dom';

import PageComponent from './content/PageComponent';

import axios from 'axios';
import CountryComponent from './content/CountryComponent';
import AssistCategoryComponent from './assistcategory/AssistCategoryComponent';

const DynamicPageLoader = () => {
  const { slug } = useParams();
  const [pageType, setPageType] = useState(null);
  const [pageId, setPageId] = useState(null);
  const [languageId, setLanguageId] = useState(null);
  const BASE_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
   
    const link = `${BASE_URL}/v1/Route/GetSlug?slug=${slug}`;
  
    axios.get(link)
      .then(response => {
        const data = response.data;
        if (data.Success) {
          setPageType(data.Data.TypeID); 
          setPageId(data.Data.DataID); 
          setLanguageId(data.Data.LanguageID);
        } else {
          throw new Error(data.Message);
        }
      })
      .catch(
        error => console.error('Sayfa verileri yüklenemedi:', error));
  }, []);

 

  if (!pageType) return false;
  
 
  switch (pageType) {
    
    case 3:
      return <PageComponent id={pageId} languageId={languageId} />;
      case 5:
        return <PageComponent id={pageId} languageId={languageId} />;
        case 13:
          return <AssistCategoryComponent id={pageId} languageId={languageId} />;
    default:
      
      return <Redirect to="/404" />;
   
  }


};

export default DynamicPageLoader;
