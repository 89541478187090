// App.js
import React,{useState,useEffect}from "react";
import { BrowserRouter } from "react-router-dom";
import store from "./Store"; 
import AuthStore from './Store/AuthStore'; // AuthStore dosyanızın yolunu doğru şekilde belirtin
import Routes from "./Routes";
import { Provider } from "mobx-react";
import { createGlobalStyle } from 'styled-components';
import BasketStore from "./Store/BasketStore";
import { PurchaseProvider } from "./contexts/PurchaseContext";
import { CountryProvider } from "./contexts/CountryContext";
import ReactGA from 'react-ga4';
import { AssistProvider } from "./contexts/AssistanceContext";

const rootStore = {
  AuthStore,
  BasketStore
  // Diğer store'larınızı da buraya ekleyebilirsiniz
};

ReactGA.initialize('G-JPPSTRCRGK');


function App() {

useEffect(() => {
  document.body.style.backgroundColor = "#eff2ee"; 
}, []);

  return (
    <Provider {...rootStore}> 
    <PurchaseProvider>
    <CountryProvider>
      <AssistProvider>
      <BrowserRouter>
        <div className="App">
          <Routes />
        </div>
      </BrowserRouter></AssistProvider>
      </CountryProvider>
      </PurchaseProvider>
    </Provider>
  );
}

export default App;
