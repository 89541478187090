import React from "react";
import parse, { domToReact } from "html-react-parser";


const PlanCard = ({ plan }) => {


    const contentList = parse(plan.FullText, {
        replace: (domNode) => {
            if (domNode.name === "li") {
                return (
                    <li className="flex space-x-3 mt-3">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="flex-shrink-0 h-5 w-5 text-green-400"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            strokeWidth="2"
                            stroke="currentColor"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            aria-hidden="true"
                        >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                            <path d="M5 12l5 5l10 -10"></path>
                        </svg>
                        <span className="text-base text-slate-700 text-base">
                            {/* `domToReact(domNode.children)` ile alt satır ve küçük font işlemi */}
                            {domNode.children.map((child, index) => {
                                if (child.type === "tag" && child.name === "br") {
                                    return <br key={index} />;
                                }
                                return (
                                    <span
                                        key={index}
                                        className={
                                            index > 0 ? "text-xs text-slate-500 mt-1" : ""
                                        }
                                    >
                                        {domToReact([child])}
                                    </span>
                                );
                            })}
                        </span>
                    </li>
                );
            }
        },
    });
    return (
        <div class="border border-slate-200 rounded-lg shadow-sm divide-y divide-slate-200 bg-grayopa">
        <div class="p-6">
            <h2 class="text-xl leading-6 font-bold text-slate-900">{plan.Title}</h2>
            <p class="mt-2 text-base text-slate-700 leading-tight">
             {plan.Desc}
             </p>
             <p className="mt-8">
    {plan.CustomPrice ? (
        <span className="text-lg font-medium text-slate-700">
           
        </span>
    ) : (
        <>
            <span className="text-sm font-medium text-slate-500 line-through">
                €{plan.Price}
            </span>
            <span className="ml-2 text-4xl font-bold text-slate-900 tracking-tighter">
                €{plan.DiscountPrice}
            </span>
            <span className="text-base font-medium text-slate-500">/6 Aylık</span>
        </>
    )}
</p>

<a
    href={plan.CustomPrice ? "/offer" : "/dash"}
    className="mt-8 block w-full bg-green rounded-md py-2 text-sm font-semibold !text-white text-center"
>
    {plan.CustomPrice ? "Teklif Oluştur" : "Başvur"}
</a>
{plan.Picture2 && (
    <div className="flex items-center justify-center mt-4">
        <img src={plan.Picture2} alt="Güvence Logosu" className="w-2/6 mr-2" />
        <span className="text-xs text-slate-500">güvencesiyle</span>
    </div>
)}


        </div>
        <div class="pt-6 pb-8 px-6">
            <h3 class="text-sm font-bold text-slate-900 tracking-wide uppercase">Paket İçeriği</h3>
            <ul role="list" class="mt-4 space-y-3">
            {contentList}
           
            </ul>
        </div>
    </div>

    //   <div className="border border-gray-200 rounded-lg p-6 shadow-lg w-full max-w-xs mx-auto">
    //     {/* Icon and Title */}
    //     <div className="flex justify-center items-center mb-4">
    //       <img src="/path/to/icon.svg" alt="Icon" className="h-8 w-8 mr-2" />
    //       <h2 className="text-lg font-semibold">{plan.Name}</h2>
    //     </div>
  
    //     {/* Description */}
    //     <p className="text-gray-500 mb-4">{plan.Desc}</p>
  
    //     {/* Price */}
    //     <p className="text-3xl font-bold text-gray-800 mb-1">{plan.Price}</p>
    //     <p className="text-sm text-gray-400 mb-6">Lisans başına aylık ücret, yıllık faturalandırılır</p>
  
    //     {/* Free Trial Button */}
    //     <button className="bg-purple-100 text-purple-700 font-semibold py-2 px-4 rounded-lg mb-4">
    //       Ücretsiz deneyin
    //     </button>
    //     <p className="text-sm text-gray-400 mb-6">14 gün ücretsiz deneme. Kredi kartı gerekmez.</p>
  
    //     {/* Key Features */}
    //     <h3 className="text-md font-semibold mb-2">Kilit özellikler</h3>
    //     <ul className="space-y-2">
    //     <li  className="flex items-start text-gray-600">
    //           <span className="mr-2 text-gray-400">ℹ️</span> {/* Replace with actual icon if available */}
    //           <span>Test Açıklamalar</span>
    //         </li>
    //     </ul>
    //   </div>
    );
  };
  

const AssistPackageComponent = ({ plans }) => {
    return (

<div class="sm:flex sm:flex-col sm:align-center">
    <div class="relative self-center bg-slate-200 rounded-lg flex">
      
    </div>
    <div
        class="mt-4 space-y-3 sm:mt-5 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-6 md:mx-auto xl:grid-cols-3">
              {plans.map((plan, index) => (
            <PlanCard key={index} plan={plan} />
          ))}
      
       
    </div>
</div>
        
       
    );
  };
  
  export default AssistPackageComponent;